import { Component } from 'react';
import star from '../assets/img/star.png';
import starEmpty from '../assets/img/star_empty.png';

class Rating extends Component {
	render() {
		const { rating } = this.props;
		const stars = [];
		for (let i = 0; i < 5; i++) {
			if (i < rating) {
				stars.push(<img key={i} src={star} alt="star" />);
			} else {
				stars.push(<img key={i} src={starEmpty} alt="star" />);
			}
		}
		return <div className="GoogleReview__rating">{stars}</div>;
	}
}

export default Rating;
