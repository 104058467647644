import { Component } from 'react';
import logo from '../assets/img/logo.png';

class Logo extends Component {
	render() {
		return (
			<div className="Amplify-logo">
				<span className="Amplify-logo__text">Powered by</span>
				<img src={logo} alt="Powered by Amplify"  className="Amplify-logo__img"/>
			</div>
		);
	}
}

export default Logo;
