import axios from 'axios';

const BASE_URL =
	process.env.NODE_ENV === 'production'
		? process.env.REACT_APP_LIVE_API
		: process.env.REACT_APP_TEST_API;

export function fetchReviews(code) {
	if (!code) {
		return Promise.reject(new Error('Code is required'));
	}

	return axios
		.get(BASE_URL + '?action=reviews&code=' + code)
		.then((response) => {
			if (response && response.data && response.data.success) {
				return (response.data.data && response.data.data.reviews) || [];
			} else throw new Error('No reviews found');
		})
		.catch((error) => {
			throw new Error(error);
		});
}
