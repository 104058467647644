import './App.css';
import Slider from './component/Slider';
import Logo from './component/Logo';
import { Routes, Route, useParams } from 'react-router-dom';

function App() {
	return (
		<div className="Amplify-widget">
			<Routes>
				<Route path="/v1/:campaignId" element={<WidgetV1 />} />
			</Routes>
		</div>
	);
}

function WidgetV1() {
    const params = useParams();
	return (
		<div className="Amplify-widget__wrapper">
			<header className="Amplify-widget__header">
				<Logo />
			</header>
			<main className="Amplify-widget__main">
				<Slider campaignId={params.campaignId} />
			</main>
		</div>
	);
}

export default App;
