import { Component } from 'react';
import Rating from './Rating';
import { fetchReviews } from '../Api.js';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import moment from 'moment';

class Slider extends Component {
	state = {
		splideOptions: {
			type: 'loop',
			rewind: true,
			arrows: false,
			pagination: false,
			gap: '20px',
			height: '120px',
			direction: 'ttb',
			autoplay: true,
		},
		reviews: null,
	};
	componentDidMount() {
		this.fetchData(this.props.campaignId);
	}
	async fetchData(id) {
		if (!id) return;
		try {
			const reviews = await fetchReviews(id);
			this.setState({ reviews });
		} catch (error) {
			console.log(error);
		}
	}
	render() {
		return (
			<Splide options={this.state.splideOptions}>
				{this.state.reviews &&
					this.state.reviews.length > 0 &&
					this.state.reviews.map((review, index) => (
						<SplideSlide key={index}>
							<div className="GoogleReview">
								<div className="GoogleReview__header">
									<h2 className="GoogleReview__name">{review.name}</h2>
									<Rating rating={review.rating} />
								</div>
								<p className="GoogleReview__review_date">
									Posted {moment(review.review_date).from(moment())}
								</p>
								<div className="GoogleReview__review_text">
									{review.review_text ? review.review_text : 'No review text'}
								</div>
							</div>
						</SplideSlide>
					))}
			</Splide>
		);
	}
}

export default Slider;
